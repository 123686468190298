import $ from 'jquery';
import 'popper.js';
import 'bootstrap/js/src/util'
import 'bootstrap/js/src/collapse'



$( document ).ready(function() {
    $('.collapse').collapse({
        toggle: false
    });
});
