import $ from 'jquery';

const tabsSection = '.tabs',
      tabsBtnMore = '.tabs__btn-more',
      tabsList = '.tabs__list',
      openedClass = 'tabs__btn-more--opened';

let breakpointMobile = 767;

const desktopList = () => {
    $(tabsList).show();
    $(tabsBtnMore).addClass(openedClass);
};

const mobileList = () => {
    $(tabsList).hide();
    $(tabsBtnMore).removeClass(openedClass);
};

/* get breakpoint value */
if (  typeof window.enfc_js !== 'undefined' ) {
    breakpointMobile = window.enfc_js.globalVars.breakpointMobile;
}

/* call functions mobile/desktop */
window.enfc.fn.checkMobileListener(breakpointMobile,mobileList,desktopList);


// tab navigation using arrow keys
window.addEventListener("DOMContentLoaded", function () {
        const tabs = document.querySelectorAll('[role="tab"]');
        const tabList = document.querySelector('[role="tablist"]');
        let tabFocus = 0;

        tabs.forEach(tab => {
                tab.addEventListener("click", changeTabs);
        });

        if (tabList) {

                tabList.addEventListener("keydown", function (e) {
                        // make the tab selectable using 'Spacebar'
                        if(e.code === 'Space'){
                            e.preventDefault();

                            changeTabs(e);
                            e.target.click();
                        }

                        if (e.keyCode === 39 || e.keyCode === 37) {
                                tabs[tabFocus].setAttribute("tabindex", -1);
                                if (e.keyCode === 39) {
                                        tabFocus++;

                                        if (tabFocus >= tabs.length) {
                                                tabFocus = 0;
                                        }

                                } else if (e.keyCode === 37) {
                                        tabFocus--;

                                        if (tabFocus < 0) {
                                                tabFocus = tabs.length - 1;
                                        }
                                }

                                tabs[tabFocus].setAttribute("tabindex", 0);
                                tabs[tabFocus].focus();
                        }
                });
        }
});

function changeTabs(e) {
        const target = e.target;

        document.querySelectorAll('.tabs__link[aria-selected="true"]')
                .forEach(el => el.setAttribute("aria-selected", false));

        target.setAttribute("aria-selected", true);

        document.querySelectorAll('div.tabs__card-body[role="tabpanel"]')
                .forEach(el => el.setAttribute("hidden", true));

        document.querySelector(`#${target.getAttribute("aria-controls")}`)
                .removeAttribute("hidden");
}

const showSelectedTab = function () {

        const tab = document.getElementsByClassName("tabs__item");
        const card = document.getElementsByClassName("tabs__card-body");

        for (let i = 0; i < tab.length; i++) {
                tab[i].addEventListener('click', function (e) {
                        e.preventDefault();

                        for (let i = 0; i < tab.length; i++) {
                            tab[i].classList.remove('active');
                        }

                        for (let i = 0; i < card.length; i++) {
                            card[i].classList.remove('active');
                        }

                        this.className += ' active';

                        let matchingCard = this.getAttribute('data-tab');

                        document.getElementById(matchingCard).className += ' active';
                        let slug = convertToSlug(this.textContent);
                        slug = slug.slice(1, -1);
                        window.location.hash = slug;

                        const $firstTabItem = $(`#${matchingCard}`);
                        window.enfc.fn.checkMobile(766) && $('html, body').animate({
                            scrollTop: $firstTabItem.offset().top
                        }, 500);
                }, false);
        }
}

showSelectedTab();

const convertToSlug = function (text) {
/* Converts e.g. 'How will we use the information about you?' into '-how-will-we-use-the-information-about-you-' */
        return text.toLowerCase()
                .replace(/[^\w ]+/g, '')
                .replace(/ +/g, '-');
};

const showHashedTab = function () {
        const tab = document.getElementsByClassName("tabs__item");
        const card = document.getElementsByClassName("tabs__card-body");
        const tabOne = document.getElementById('one-tab');
        let currentHash = window.location.hash;

        if (currentHash) {
                currentHash = currentHash.replace("#", "");
                for (let i = 0; i < card.length; i++) {
                        card[i].classList.remove('active');
                };

                for (let i = 0; i < tab.length; i++) {
                        let slug = convertToSlug(tab[i].textContent);
                        slug = slug.slice(1, -1);
                        if (slug === currentHash) {
                                let matchingCard = tab[i].getAttribute('data-tab');
                                tab[i].className += ' active';
                                document.getElementById(matchingCard).className += ' active';
                        } else {
                                tab[i].classList.remove('active');
                        }
                };
        } else {
                if (tabOne && tabOne.classList.contains('active')) {
                        let slug = convertToSlug(tabOne.textContent);
                        window.location.hash = slug;
                }
        }
};

$(tabsBtnMore).on('click',function() {

    $(this).toggleClass(openedClass);
    $(this).closest(tabsSection).find(tabsList).slideToggle();
});

showHashedTab();
