import $ from 'jquery';
import 'slick-carousel';

const sliderSelector = '.js-carousel__list',
      toggleBtn = '.js-carousel__toggle',
      toggleClass = 'toggle-more',
      arrowReverseClass = 'reverse';

let breakpoint = 1024;

/* Init module */
const init = () => {
    /* get breakpoint value */
    typeof window.enfc_js !== 'undefined' && ( breakpoint = window.enfc_js.globalVars.breakpointTablet );
    /* call functions mobile/desktop */
    window.enfc.fn.checkMobileListener(breakpoint,initMobile,initDesktop);

    bind();
};

const initMobile = () => {

    destroySlider();
    // display less
    $(sliderSelector).find('>li:not(:first-child)').slideUp();
};

const initDesktop = () => {

    initSlider();
    $(sliderSelector).removeClass(toggleClass);

    // img object-fit - support for IE11
    window.enfc.fn.objectFitSupport('.carousel__figure');
};

/* Innit slider */
const initSlider = () => {

    $(sliderSelector).slick({
        arrows: true,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        accessibility: true,
        fade: true,
        prevArrow: '<button type="button" class="slick-prev"><span' + ' class="sr-only">Previous</span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>'
    }).on('afterChange', function(event, slick){
        let $prevArrow = $(slick.$prevArrow[0]),
            $nextArrow = $(slick.$nextArrow[0]);
       // WCAG - tab navigation
        $prevArrow.hasClass('slick-disabled') ? $prevArrow.attr('tabindex','-1')
            : $prevArrow.removeAttr('tabindex');
        $nextArrow.hasClass('slick-disabled') ? $nextArrow.attr('tabindex','-1')
            : $nextArrow.removeAttr('tabindex');
    });
    // WCAG - tab navigation
    $(sliderSelector).find('.slick-prev').attr('tabindex','-1');
};

/* destroy slick on mobile */
const destroySlider = () => {

    $(sliderSelector).hasClass('slick-initialized') && $(sliderSelector).slick('unslick');
};

const bind = () => {

    // display more/less
    $(toggleBtn).on('click',function(e){
        e.preventDefault();

        let moreText = typeof $(this).attr('data-more') !== 'undefined' ? $(this).attr('data-more') : 'More',
            lessText = typeof $(this).attr('data-less') !== 'undefined' ? $(this).attr('data-less') : 'Less',
            $list = $(this).prev();

        $(this).toggleClass(arrowReverseClass);
        $list.toggleClass(toggleClass);

        if ( $list.hasClass(toggleClass) ) {
            $(this).text(lessText);

            $list.find('>li:not(:first-child)').slideDown();
        } else {
            let $firstEl = $list.find('>li:nth-child(1)');
            $(this).text(moreText);
            $list.find('>li:not(:first-child)').slideUp();
            $('html, body').animate({
                scrollTop: $firstEl.offset().top + $firstEl.height()
            }, 500);
        }
    });
};

$(sliderSelector).length && init();

