import $ from 'jquery';
import { getContent } from '../../../modules/api/js/global';

const filterBtn = '.js-filters-mobile_btn',
      filterBtnClose = '.js-filters__close',
      filterPanel = '.filters',
      filtersClass = 'filters-opened',
      bodyClass = 'filters-active',
      filterSearchBtn = '.js-filters-search__btn',
      filterField = '.js-filters__field',
      htmlContent = '.filter__results',
      errorClassEl = '.filters__error',
      requiredError = '<span class="filters__error">Required field</span>',
      dependentField = '.js-field-dependent',
      loadContentSelector = '.js-filters-load';

let searchUrl = '';

/* Init module */
const init = () => {

    /* get url value */
    $(filterPanel).attr('data-url') && ( searchUrl = $(filterPanel).attr('data-url') );

    bind();
};

const checkedRequired = ($item) => {
    let parent = $item.parent(),
        errorEl = $(parent).find(errorClassEl);

    if ( $item.hasClass('required') ) {

        if ( !$item.val() ) {

            !errorEl.length && $(parent).append(requiredError);

            return false;
        } else {
            errorEl.remove();
        }
    }

    return true;
};

const getParam = (el) => {

    let type = $(el).prop('tagName').toLowerCase(),
        connector = {},
        params = {
            select: function() {

                // get parameter
                connector = {[$(el).attr('name')]:[$(el).val()]};

                return connector;
            },
            input: function() {
                let type = $(el).prop('type'),
                    arrayValues = '';

                // get parameter

                if (( $(el).is(':checked') ) || type === 'text') {
                    arrayValues = $(el).val().split(',');

                    connector = {[$(el).attr('name')]: arrayValues};
                  //  console.log(connector);
                }

                return connector;
            },
            default: function() {

                return '';
            },
        };

    return (params[type] || params['default'])();
};

const ajaxContent = () => {
    let validateState = 0,
        params = {},
        url = '';

    if ( searchUrl ) {
        $(loadContentSelector).addClass('loading');

        $(filterField).each(function(){
            let paramValue = getParam(this),
                state = 0;

            if ( paramValue ) {
                // get parameters

                Object.keys(params).map(function(el){

                    if ( el === Object.keys(paramValue)[0] && params[el] !== paramValue[el] ) {

                        params[el] = typeof params[el] === 'string' ? [params[el],paramValue[el]] : [...params[el],...paramValue[el]];
                        state = 1;
                    }

                    return params[el];
                });

                !state && ( params = {...params,...paramValue} );

            }

            !checkedRequired($(this)) && (validateState = validateState + 1);
        });

        console.log(JSON.stringify(params));

        Object.keys(params).length && ( url = searchUrl + '?' + $.param(params,true) );

        !validateState && getContent(url,response => {
            // response
            $(htmlContent).html(response);
            $(loadContentSelector).removeClass('loading');

        }).fail(function(er) {

            console.warn('Getting news list error: ' + er.status + ' - ' + er.statusText);
            $(loadContentSelector).removeClass('loading');
        });

    }

    !validateState && $(filterBtnClose).trigger('click');

};

const bind = () => {

    // open filter
    $(document).on('click',filterBtn,function(e) {
        e.preventDefault();

        $(filterPanel).addClass(filtersClass);
        $('body').addClass(bodyClass);
    });

    // close filter
    $(document).on('click',filterBtnClose,function(e) {
        e.preventDefault();

        $(filterPanel).removeClass(filtersClass);
        $('body').removeClass(bodyClass);
    });

    // filtration - getting the results
    $(filterSearchBtn).on('click',function(e){
        e.preventDefault();

        ajaxContent();
    });

    $(document).on('change','.required',function(){
        checkedRequired($(this));
    });

    $(dependentField).on('change',function(){
        let linkedField = $(this).attr('data-dependent') ? $(this).attr('data-dependent') : '',
            urlOptions = linkedField ? $(linkedField).attr('data-url') : '';

        if ( urlOptions ) {

            urlOptions = urlOptions + '?' + $.param(getParam(this),true);


            getContent(urlOptions,response => {
                // response
                $(linkedField).html(response);

            }).fail(function(er) {

                console.warn('Getting news list error: ' + er.status + ' - ' + er.statusText);
            });
        }
    });

    $(loadContentSelector).on('change','input',function(){
        ajaxContent();
    });

};

init();
