import $ from 'jquery';

/**
 * functions for management menu section
 */

const targetButton = '.js-header-menu__close',
      menuSelector  = '.header-menu',
      topMenuSelector = '.header-menu__top',
      bottomMenuSelector = '.header-menu__bottom',
      bodyClass = 'menu-active',
      elToTabIndex = 'a,button',
      hamburgerBtn = '.js-header-hamburger',
      servicesEl = '.all-services__list--menu li',
      $focusableInNav = $('#header-menu-section button, #header-menu-section [href], #header-menu-section' +
          ' [tabindex]:not([tabindex="-1"])'),
      $firstFocusableElement = $focusableInNav.first(),
      $lastFocusableElement = $focusableInNav.last();

let breakpointMobile = 768;

 /* Init module */
const init = () => {


    /* inaccessibility of elements */
    $(menuSelector).find(elToTabIndex).attr('tabindex','-1');

    /* get breakpoint value */
    if (  typeof window.enfc_js !== 'undefined' ) {
        breakpointMobile = window.enfc_js.globalVars.breakpointMobile;
    }

    /* call functions mobile/desktop */
    window.enfc.fn.checkMobileListener(breakpointMobile,mobileMenu,desktopMenu);

    bind();
};

/* set up the same height for all elements, ensure compatibility with the design */
const setHeightItem = () => {
    let tallest = 0;

    /* cleared height of items */
    $(menuSelector).find(servicesEl).removeAttr( 'style' );

    /* get tallest element */
    $(menuSelector).find(servicesEl).each(function(){
        if($(this).outerHeight() > tallest)
            tallest = $(this).outerHeight();
    });

    /* set up tallest height for all elements */
    $(menuSelector).find(servicesEl).css('height', tallest + 'px');
};

const bind =  () => {

        /* adding a class for the body element when the hamburger button is clicked, inaccessibility of elements,
         aria tags */
        $(targetButton).on('click',function (e) {
            e.preventDefault();

            closeNav();
        });

        $(targetButton).on('keyup',function (e) {
            e.preventDefault();

            if (e.key === 'Tab' && e.key !== 'Escape') {
                return;
            }

            $(hamburgerBtn).focus();
        });

        $(menuSelector).on('keyup',function (e) {
            e.preventDefault();

            // accessibility
            if (e.type === 'keyup' && e.key !== 'Escape') {
                return;
            }

            closeNav();
        });

        $(document).on('click focus keyup', function (e) {

            if (!$(e.target).is(hamburgerBtn) && !$(e.target).is(hamburgerBtn + ' *') && !$(e.target).is(menuSelector)
                && !$(e.target).is(menuSelector + ' *')) {

                closeNav();
            }
        });

        // accessibility focus loop
        $firstFocusableElement.on('keydown', moveFocusToBottom);
        $lastFocusableElement.on('keydown', moveFocusToTop);

};

const mobileMenu = () => {

    /* need to add top padding because the top of the menu gets "fixed" */
    $(menuSelector).css('padding-top', window.enfc.fn.pxToRem($(topMenuSelector).outerHeight()) + 'rem');
    /* when the mobile view need to add bottom padding because the bottom of the menu gets "fixed" */
    $(menuSelector).css('padding-bottom', window.enfc.fn.pxToRem($(bottomMenuSelector).outerHeight()) + 'rem');
    /* set up the height of the elements */
    setHeightItem();
};

const desktopMenu = () => {

    /* reset style */
    $(menuSelector).css({});
    /*  need to add top padding because the top of the menu gets "fixed" */
    $(menuSelector).css('padding-top', window.enfc.fn.pxToRem($(topMenuSelector).outerHeight()) + 'rem');
    /* set up the height of the elements */
    setHeightItem();
};

const moveFocusToTop = (e) => {
    if (e.key === 'Tab' && !e.shiftKey) {
        e.preventDefault();
        $firstFocusableElement.focus();
    }
};

const moveFocusToBottom = (e) => {
    if (e.key === "Tab" && e.shiftKey) {
        e.preventDefault();
        $lastFocusableElement.focus()
    }
};

const closeNav = ()  => {
    const bodyEl = 'body';

    if ( $(bodyEl).hasClass(bodyClass)) {

        $(bodyEl).removeClass(bodyClass);
        /* inaccessibility of elements */
        $(menuSelector).find(elToTabIndex).attr('tabindex', '-1');
        /* aria information */
        $(hamburgerBtn).attr('aria-expanded', false);
        $(menuSelector).attr('aria-hidden', true).attr('tabindex', '-1');
    }
};

$(window).on('load', function() {
    init();
});

/* set up the height of the list elements when resize */
$(window).on('resize', function() {
    setHeightItem();
});
