import $ from 'jquery';

(function(){
    'use strict';

    // Global variables
    var enfc = {
        'fn': {},
        'vars': {}
    };

    // check if browser is IE11
    enfc.vars.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    enfc.fn.checkMobile = function(size) {
        let viewport = window.matchMedia('screen and (max-width:768px)');

        if ( size !== undefined && parseInt(size) !== 'NaN' ) {
            viewport = window.matchMedia('screen and (max-width:' + size + 'px)');
        }

        if ( viewport.matches ) {
            return true;
        }

        return false;
    };

    // Functions
    enfc.fn.checkMobileListener = function(size, callback, callback2) {
        let viewport = window.matchMedia('screen and (max-width:768px)');

        if (size !== undefined && parseInt(size) !== 'NaN') {
            viewport = window.matchMedia('screen and (max-width:' + size + 'px)');
        }

        if (typeof callback === 'function' && typeof callback2 === 'function') {
            if (viewport.matches) {
                callback();
            } else {
                callback2();
            }
        } else {
            console.log('callback is not a function');
        }

        viewport.addListener(function(mq) {
            if (typeof callback === 'function' && typeof callback2 === 'function') {
                if (mq.matches) {
                    callback();
                } else {
                    callback2();
                }
            } else {
                console.log('callback is not a function');
            }
        });
    };

    enfc.fn.pxToRem = function(sizePx) {
        let baseSize = 16;

        return sizePx/baseSize;
    };

    // eslint-disable-next-line no-unused-vars
    enfc.fn.objectFitSupport = function(target) {
        if (
            document.getElementsByTagName('html')[0].className.match(/no-objectfit/)
        ) {
            if ($(target).length) {
                $(target).each(function() {
                    let item, img, imgUrl;

                    item = this;
                    img = item.getElementsByTagName('img')[0];
                    img && (imgUrl = img.src);

                    if (typeof imgUrl !== 'undefined') {
                        item.classList.add('object-fit');
                        item.style.backgroundImage = 'url(' + imgUrl + ')';
                    }
                });
                return true;
            }
        }
        return false;
    };

    // Make pil variable available to the console for debugging
    window.enfc = enfc;
}());

// OBJECT FIT SUPPORT
if ('objectFit' in document.documentElement.style) {
    let currentClass = document.getElementsByTagName('html')[0].className,
        newClass = currentClass.concat(' objectfit');
    document.getElementsByTagName('html')[0].className = newClass;
} else {
    let currentClass = document.getElementsByTagName('html')[0].className,
        newClass = currentClass.concat(' no-objectfit');
    document.getElementsByTagName('html')[0].className = newClass;
}


$('.main table').each(function () {
    var headerCount = $(this).find('thead th').length;

    for (let i = 0; i <= headerCount; i++) {
        var headerLabel = $(this).find('thead th:nth-child(' + i + ')').text();

        $(this).find('tr td:not([colspan]):nth-child(' + i + ')').replaceWith(
            function () {
                return $('<td data-title="' + headerLabel + '">').append($(this).contents());
            }
        );
    }

});

$(window).on('load', function() {

    $('.align-left,.align-right').each(function(){

        $(this).width() <= 100 && $(this).addClass('small-img');

    });

    $('body').removeClass('preload');
});
