import $ from 'jquery';

let breakpoint = 767;
const $heroBanner = $(".hero-banner");
const $player = $(".hero-banner__video");
const autoplayAttr = $player.attr('autoplay');
const $btnWrapper = $('.hero-banner__video-controls-wrapper');

/* Init module */
const init = () => {
  /* get breakpoint value */
  typeof window.enfc_js !== 'undefined' && ( breakpoint = window.enfc_js.globalVars.breakpointTablet );
  /* call functions mobile/desktop */
  window.enfc.fn.checkMobileListener(breakpoint,initMobile,initDesktop);
};

const initMobile = () => {
  $player.hide();
  $btnWrapper.hide();
  $player.removeAttr("autoplay");
  $heroBanner.removeClass('video');
};

const initDesktop = () => {
  $player.show();
  $btnWrapper.show();
  $heroBanner.addClass('video');
  if (typeof autoplayAttr == 'undefined' || autoplayAttr == false) {
    $player.setAttr("autoplay", "");
  }
};

window.addEventListener('load', function () {

  // custom video controls - play and pause
  const $playPauseBtn = $('.play');

  if ($playPauseBtn && $playPauseBtn.length) {
    // remove native controls
    $player.removeAttr('controls');

    $playPauseBtn.on('click', function (e) {
      e.preventDefault();
      const $video = $(this).closest('.hero-banner').find('.hero-banner__video');

      if ($video.prop('paused') == true) {
        $video.trigger("play");
        $(this).addClass('pause');
      } else {
        $video.trigger("pause");
        $(this).removeClass('pause');
      }
    })
  }
});

$(window).on('load', function() {
  $player.length && init();
});