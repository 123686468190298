import $ from 'jquery';

const dropdownLink = '.header-links__user',
      classFocus = 'focusin',
      container = '.header-links__item-user';

function focusDropdown() {

    $(dropdownLink).on('mousedown', function(e) {
        e.preventDefault();  // don't grab focus
    });

    $(dropdownLink).on('focus', function(e) {
        e.preventDefault();

        $(this)
            .parent()
            .addClass(classFocus)
            .siblings()
            .removeClass(classFocus);
    });

    $(document).on('click focus keyup', function(ev) {
        if (!$(ev.target).is(container + ' *')) {
            $(dropdownLink)
                .parent()
                .removeClass(classFocus);
        }
    });
}

focusDropdown();
