import $ from 'jquery';

export function fetch(methodType = 'get', url, query, callback) { //eslint-disable-line
    return $.ajax({
        url:     url,
        type:    methodType,
        data:    query,
        cache:   false,
        success: callback,
    });
}

/**
 * Get list content
 * @param {string} urlList - url for items
 * @param {function} cb - Callback function
 */
export function getContent(urlList,cb = () => {}) {
    return fetch('get', urlList, '', cb);
}
