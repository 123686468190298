import $ from 'jquery';

/**
 * functions for management hamburger button
 */

const targetButton = '.js-header-hamburger',
      bodyClass = 'menu-active',
      menuSelector = '.header-menu',
      elToTabIndex = 'a,button';

 /* Init module */
const init = () => {

        bind();
};

const bind =  () => {

        /* adding a class for the body element when the hamburger button is clicked, accessibility of elements aria */
        $(targetButton).on('click',function (e) {
            e.preventDefault();

            $('body').addClass(bodyClass);
            /* accessibility of elements */
            $(menuSelector).find(elToTabIndex).attr('tabindex','0');
            /* aria information */
            $(this).attr('aria-expanded',true);
            $(menuSelector).attr('aria-hidden',false).attr('tabindex','0').focus();
        });
};

init();

