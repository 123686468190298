import $ from 'jquery';

const sectionSelector = '.choicelist',
      selectedBtn = '.choicelist__btn',
      textForBtn = '.choicelist__btn-text',
      selectedList = '.choicelist__list',
      classOpen = 'choicelist__btn--open';

/* Init module */
const init = () => {

    bind();
};

/* bind module */
const bind = () => {

    $(selectedBtn).on('click',function(e){
        e.preventDefault();

        manageChoiceList($(this));
    });

    $(selectedList).on('change','input[type="checkbox"]', function(e) {
        let btn = $(e.delegateTarget).prev(selectedBtn),
            btnText = btn.find(textForBtn),
            facet = typeof btn.attr('data-facet') !== 'undefined' ? btn.attr('data-facet') : 'item',
            itemCount = $(e.delegateTarget).find("input:checked").length;

        if (itemCount === 0)
            btnText.html('Choose ' + facet);
        else if (itemCount === 1)
            btnText.html($("label[for='" + $("input:checked")[0].id + "']").text());
        else
            btnText.html(itemCount + ' ' + facet + ' selected');
    });

    $(selectedList).on('keyup','input[type="checkbox"]', function(e) {

        // close list when click esc key, only when input has "focus"
        if (e.keyCode === 27)
        {
            let btn = $(e.delegateTarget).prev(selectedBtn);
            manageChoiceList($(btn));
            $(btn).focus();
        }
    });

    // close the list if the area after the element is clicked
    $(document).on('click focus keyup', function (e) {
        if (!$(e.target).is(sectionSelector + ' *')) {

            $(selectedBtn).each(function(){

                if ($(this).attr('aria-expanded') === 'true') {
                    $(this).attr('aria-expanded', 'false');
                    $(this).next(selectedList).slideUp();
                    $(this).removeClass(classOpen);
                }
            });
        }
    });
};

// manage checkbox list
const manageChoiceList = (relatedBtn) => {

    if (relatedBtn.attr('aria-expanded') === 'true') {
        relatedBtn.attr('aria-expanded', 'false');
        relatedBtn.next(selectedList).slideUp();
        relatedBtn.removeClass(classOpen);
    } else {
        relatedBtn.attr('aria-expanded', 'true');
        relatedBtn.next(selectedList).slideDown();
        relatedBtn.addClass(classOpen);
    }
};

$(sectionSelector).length && init();
