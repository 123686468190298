import $ from 'jquery';

const toggleBtn = '.js-btn-toggle',
      arrowReverseClass = 'reverse';

let breakpointTablet = 1024,
    breakpointMobile = 768;

/* Init module */
const init = () => {

    /* get breakpoint value */
    if (  typeof window.enfc_js !== 'undefined' ) {
        breakpointTablet = window.enfc_js.globalVars.breakpointTablet;
        breakpointMobile = window.enfc_js.globalVars.breakpointMobile;
    }

    /* call functions mobile/tablet/desktop */
    window.enfc.fn.checkMobileListener(breakpointMobile,toggleMobile,toggleTablet);
    window.enfc.fn.checkMobileListener(breakpointTablet,toggleTablet,toggleDesktop);

    bind();
};

/* for mobile */
const toggleMobile = () => {

    toggleShow(2);
};

/* for tablet */
const toggleTablet = () => {

    switch(true) {
        case window.enfc.fn.checkMobile(breakpointMobile):
            toggleMobile();
            break;
        case !window.enfc.fn.checkMobile(breakpointTablet):
            toggleDesktop();
            break;
        default:
            toggleShow(1);
    }
};

/* for desktop */
const toggleDesktop = () => {

    toggleShow(0);
};

/* get array 'data-show' for each button / call toggle function with number of items */
const toggleShow = (number) => {

    $(toggleBtn).each( function() {
        let btn = $(this),
            showEl = typeof btn.attr('data-show') !== 'undefined' ? JSON.parse(btn.attr('data-show')) : [12,6,3],
            listEl = btn.prev('ul'),
            cloneInfoItems = $(listEl).find('li.sr-only').clone();

        $(listEl).find('li').not('.sr-only').show();

        // removed accessibility information
        $(listEl).find('li.sr-only').remove();
        //added accessibility information in the appropriate place
        cloneInfoItems.insertBefore($(listEl).find('li').eq(showEl[number]));
        btn.show();

       !$(this).hasClass(arrowReverseClass) && toggleElements($(this),showEl[number]);
    });
};

/* show/hide items */
const toggleElements = (btn,showEl,animation=0) => {

    // animation = 0 no animation when loading the page

    let moreText = typeof btn.attr('data-more') !== 'undefined' ? btn.attr('data-more') : 'More',
        lessText = typeof btn.attr('data-less') !== 'undefined' ? btn.attr('data-less') : 'Less',
        listEl = btn.prev('ul'),
        listLength = listEl.find('li').not('.sr-only').length,
        animationShow = animation ? 'slideDown' : 'show',
        animationHide = animation ? 'slideUp' : 'hide';

    if ( listEl.length ) {

        switch(true) {
            case listLength <= showEl:
                btn.hide();
                break;
            case listEl.find('li').not(':hidden').not('.sr-only').length === showEl:
                listEl.find('li')[animationShow]();
                btn.attr('aria-expanded','true').children().first().text(lessText);
                console.log(listEl.find('li').eq(7));

                $(listEl.find('li.sr-only')).focus();
                break;
            default:
                listEl.find('li').not('.sr-only').slice(showEl,listEl.find('li').not('.sr-only').length)[animationHide]();
                btn.attr('aria-expanded','false').children().first().text(moreText);
        }
    }
};

const bind = () => {

    // button event
    $(toggleBtn).on('click', function(e) {
        e.preventDefault();

        let btn = $(this),
            showEl = typeof btn.attr('data-show') !== 'undefined' ? JSON.parse(btn.attr('data-show')) : [7,8,8];

        // adding reverse class for button
        btn.toggleClass(arrowReverseClass);

        switch(true) {
            case window.enfc.fn.checkMobile(breakpointMobile):
                showEl = showEl[2];
                break;
            case window.enfc.fn.checkMobile(breakpointTablet):
                showEl = showEl[1];
                break;
            default:
                showEl = showEl[0];
        }
        // last parameter means that animation should be slide
       toggleElements($(this),showEl,1);
    });
};

init();
