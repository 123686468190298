import Cookies from 'js-cookie';

((Cookies)=>{
    /*
     * Settings
     */
    const cookieName = 'noticedRead';
    const noticeSelector = '.notice';
    const noticeHiddenClass = 'notice--hidden';
    const notices = document.querySelectorAll(noticeSelector);
    const noticeClose = document.querySelectorAll('.notice__close');

    /*
     * Functions
     */

    /**
     * Add given id to cookie that holds the IDs of read notices
     * @param {number} noticeid id of the notice
     * @param {string} cookieName name of the cookie to safe data in
     * @returns {void}
     */
    const addRemoved = (noticeid, cookieName) => {
        let readNotices = Cookies.get(cookieName) ? JSON.parse(Cookies.get(cookieName)) : [];
        readNotices.push(noticeid);
        Cookies.set(cookieName, JSON.stringify(readNotices));
    };

    /**
     * Get the ids array of read notices
     * @param {string} cookieName name of the cookie to get data from
     * @returns {array} array of ids with read notices
     */
    const getRemoved = (cookieName) => {
        return Cookies.get(cookieName) ? JSON.parse(Cookies.get(cookieName)) : [];
    };

    /**
     * Remove notice from DOM by its id
     * @param {number} noticeid id of the notice
     * @param {string} cookieName name of the cookie to safe data in
     * @param {boolean} updateCookie if true will add noticeid to cookieName
     * @returns {void}
     */
    const removeNotice = (noticeid, cookieName, updateCookie) =>{
        [...document.querySelectorAll(`${noticeSelector}[data-noticeid="${noticeid}"]`)].forEach((notice)=>{
            updateCookie ? addRemoved(noticeid, cookieName) : null;
            notice.outerHTML = '';
        });
    };

    /*
     * Init
     */
    if (notices) {

        // Remove read notices
        getRemoved(cookieName).forEach((noticeid)=>{
            removeNotice(noticeid, null, false);
        });


        // Bind close btn
        [...noticeClose].forEach((closeBtn)=>{
            closeBtn.addEventListener('click',(e)=>{
                e.preventDefault();

                const noticeid = parseInt(e.target.dataset['noticeid'],10);
                removeNotice(noticeid, cookieName, true);
            })
        });

        // Show remaining notices
        [...notices].forEach((notice)=>{
            notice.classList.remove(noticeHiddenClass);
        });
    }
})(Cookies);
