import $ from 'jquery';

/**
 * functions for management popular pages section
 */

const moduleSection = '.js-popular-pages',
      titleEl = '.popular-pages__subtitle';

let breakpointMobile = 768;

 /* Init module */
const init = () => {

    /* get breakpoint value */
    if (  typeof window.enfc_js !== 'undefined' ) {
        breakpointMobile = window.enfc_js.globalVars.breakpointMobile;
    }

    /* call functions mobile/desktop */
    window.enfc.fn.checkMobileListener(breakpointMobile,mobileMenu,desktopMenu);
};

/* set up the same height for all elements, ensure compatibility with the design */
const setHeightItem = () => {
    let tallest = 0;

    /* cleared height of items */
    $(moduleSection).find(titleEl).removeAttr( 'style' );

    /* get tallest element */
    $(moduleSection).each(function() {
        tallest = 0;

        $(this).find(titleEl).each(function () {
            if ($(this).outerHeight() > tallest)
                tallest = $(this).outerHeight();
        });

        /* set up tallest height for all elements */
        $(this).find(titleEl).css('height', tallest + 'px');
    });
};

const mobileMenu = () => {

    /* cleared height of items */
    $(moduleSection).find(titleEl).removeAttr( 'style' );
};

const desktopMenu = () => {

    /* set up the height of the elements */
    setHeightItem();
};

$(window).on('load', function() {
    init();
});

/* set up the height of the list elements when resize */
$(window).on('resize', function() {
    setHeightItem();
});
