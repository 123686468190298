import $ from 'jquery';

/* Init module */
const init = () => {
    bind();
};

const bind = () => {
    // open filter
    $('.logout-popup__close-window').on('click', function (e) {
        e.preventDefault();
        $(window).unload(function () {
            return "Bye now!";
        });
    })
    $('.sign-in__button-signIn').on('click', function (e) {
        e.preventDefault();
        var URL = $(this).attr('href');
        if (URL.indexOf("formID") > -1) {
            var formID = getUrlParameter('formID', URL);
            document.cookie = "formID=" + formID + "";
        }
        window.location.href = URL;

    });
    var keycloakCfg = {
        "userInfo" : "",
        "clearSession" :""
    };

    var checkSessionTimeout = function () {
        if (sessionStorage.getItem("date") === null) {
            sessionStorage.setItem('date', new Date());
        }
        if (localStorage.getItem("date") === null) {
            localStorage.setItem('date', new Date());
        }

        var localMinutes = Math.abs((Date.parse(localStorage.getItem("date")) - new Date()) / 1000 / 60);
        var minutes = Math.abs((Date.parse(sessionStorage.getItem("date")) - new Date()) / 1000 / 60);

        if(localMinutes > minutes) {
            minutes = localMinutes;
        }

        if (minutes > 17) {
            sessionStorage.setItem('date', new Date());
            localStorage.setItem('date', new Date());
            $.ajax({
                url: keycloakCfg.userInfo,
                success: function (resp) {
                    try {
                        const json = JSON.parse(resp);
                        if (json.error === 'invalid_request') {
                            $.ajax({
                                url: keycloakCfg.clearSession,
                                success: function () {
                                },
                            });
                        }
                    }
                    catch (err) {
                        $.ajax({
                            url: keycloakCfg.clearSession,
                            success: function () {
                            },
                        });
                    }
                },
                error: function() {
                    $.ajax({
                        url: keycloakCfg.clearSession,
                        success: function () {
                        },
                        error: function() {
                        }
                    });
                }
            });
        }
    };

    if($('.header-links__item-user').is(':visible')){
        setInterval(checkSessionTimeout, 1000);
    } else {
        localStorage.removeItem('date');
    }
};
const getUrlParameter = (param, url) => {
    url = url.split('?');
    var PageURL = url[1],
        URLVariables = PageURL.split('&'),
        ParameterName,
        i;
    for (i = 0; i < URLVariables.length; i++) {
        ParameterName = URLVariables[i].split('=');
        if (ParameterName[0] === param) {
            return ParameterName[1] === undefined ? true : ParameterName[1];
        }
    }
};

$(window).on('load', function () {
    init();
});

